import { Button, Dialog, useMediaQuery, useTheme, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { useTranslation } from "react-i18next";

const styleList = {
    fontFamily: 'Helvetica',
    color: 'rgba(0,0,0, 0.6)',
    fontWeight: 'bold',
}
const NcHelpModal = ({ open, setOpen }: { open: any, setOpen: any }) => {
    const { t } = useTranslation();
    const handleClose = () => setOpen(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                fullScreen={fullScreen}
            >
                <DialogTitle id="modal-modal-title">{t('¿Te ayudo?')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <p>Si conoces ChatGPT, podemos decir que soy el <strong>ChatGPT de AJE Granada</strong>.</p>
                        <p>Puedes preguntame cosas como:</p>

                    </DialogContentText>
                    <ul style={styleList}>
                        <li>¿Qué empresas de AJE Granada me recomiendas para hacer un catering en exteriores?</li>
                        <li>Necesito una formación sobre Inteligencia Artificial, ¿quién la puede dar?</li>
                        <li>Me dedico a la encuadernación, ¿quienes pueden ser mis clientes?</li>
                        <li>Soy desarrollador software y a veces necesito ayuda, ¿quienes podrian ayudarme?</li>
                    </ul>
                    <DialogContentText>
                        <p>{t('Se me olvidaba, soy')} <strong>{t('100% gratuito')}</strong>.</p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        {t('cerrar')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default NcHelpModal;