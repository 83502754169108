import { Box, Container, Grid, Typography, useTheme } from "@mui/material";
import Logo from '../../assets/logos/logo-aje-white.svg';
import { useTranslation } from "react-i18next";
import LogoMV from '../../assets/logos/mv-logo.svg';

const AboutPage = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    return (
        <Container maxWidth="lg" sx={{
            bgcolor: theme.palette.primary.light,
            flexGrow: 1,
            marginTop: 0,
            paddingTop: "25px"

        }}>
            <Box display="flex" justifyContent="center" my={4} sx={{ marginTop: 0 }}>
                <img src={Logo} alt="Logo" style={{ width: '150px' }} />
            </Box>
            <Typography variant="h4" component="h3" gutterBottom sx={{fontFamily: 'Arial', transform: 'rotate(-6deg)', textTransform: 'uppercase', textShadow: '2px 2px 0px #FF9999, 4px 4px 0px #FF5555', fontSize: '3rem'}}>
                Match!
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Typography variant="body1" paragraph>
                        Soy el asistente inteligente definitivo que te ayudará a conocer a otros empresarios de AJE Granada para llevar tu empresa o negocio al sigiente nivel.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Con mi funcionalidad "Match!", encuentro aquellos emprendedores y empresas de AJE que puede complementar tus productos o servicios, o ser posibles socios o incluso clientes. Ya sabes, la unión hace la fuerza.
                    </Typography>
                </Grid>
                {/*
                <Grid item xs={12} md={6}>
                    <Box>
                    </Box>
                </Grid>
                */}
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                        {t('aboutPage.project_title')}
                    </Typography>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item>
                            <a href="https://montevive.ai" target="_new">
                                <img src={LogoMV} alt="Logotipo de Montevive.ai" style={{ width: '80px' }} />

                                <p style={{ fontFamily: 'Montserrat', textTransform: 'uppercase', fontWeight: 'bold', color: "white" }}>Montevive.ai</p>
                            </a>
                            <p style={{ fontFamily: 'Montserrat' }}>AI Solutions</p>
                        </Grid>
                    </Grid>
                    
                </Grid>
                
            </Grid>
        </Container >
    )
}

export default AboutPage;