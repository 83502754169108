import React from "react";
import Loader from "./Loader";
import ConditionallyRender from 'react-conditionally-render';
import MarkdownRenderer from './MarkdownRenderer';

const MarkdownChatMessage = ({ message }: { message: any }) => {
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  });
  return (
    <div className="react-chatbot-kit-chat-bot-message" style={{ backgroundColor: "#c14452" }}>
      <ConditionallyRender
        condition={loading}
        show={<Loader />}
        elseShow={
          <div>
            <MarkdownRenderer markdownText={message} />
          </div>
        }
      />
      <div
        className="react-chatbot-kit-chat-bot-message-arrow"
        style={{ borderRightColor: "#c14452" }}
      ></div>
    </div>);
};
export default MarkdownChatMessage;