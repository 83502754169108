import { createChatBotMessage, createCustomMessage } from 'react-chatbot-kit';
import IConfig from 'react-chatbot-kit/build/src/interfaces/IConfig';
import IWidget from "react-chatbot-kit/build/src/interfaces/IWidget";
import NcAvatar from './ncAvatar/NcAvatar';
import NccAlert from './widgets/NccAlert';
import DogPicture from './widgets/DogPicture';
import NcwMap from './widgets/NcwMap';
import NcwHint from './widgets/NcwHint';
import MarkdownChatMessage from './MarkdownChatMessage';

const config: any = (t: any, latestNews: string | null) => {
    // console.log("Latest news: ", latestNews);
    let initMsgs = [
        // createCustomMessage('La procesión de "La Borriquilla" finalmente no puede salir a procesionar por la ciudad debido a las condiciones metereológicas. Hará estación de penitencia en su templo "Iglesia de Sán Andrés"', 'ncwAlert', {payload: {'msg': 'hola'}}),
        // createChatBotMessage('¡Hola! Soy tu asistente inteligente para esta Semana Santa 2024 en Granada. Puedo darte información sobre el estado de las procesiones en tiempo real, datos sobre las hermandades, información metereológica, ...', {}),
        // createChatBotMessage('También puedes acceder a la Guía de la Semana Santa Accesible 2024. ¡La más completa y accesible!', { widget: 'ncwGuide' }),
        // createChatBotMessage('¿Qué necesitas saber sobre la Semana Santa de Granada?', { widget: 'ncwHint' }),
         // createCustomMessage('La procesión "A" finalmente no puede salir a procesionar por la ciudad debido a las condiciones climatológicas. Hará estación de penitencia en su templo "B"', 'ncwAlert', {payload: {'msg': 'hola'}}),
        // createClientMessage('por dónde va la procesión del silencio?', {}),
        // createChatBotMessage('Hola, la procesión del Silencio está en la carrera del Darro. Te paso su posición exacta', {widget: 'ncwMap'})
        // createClientMessage('dónde están parando los taxis? estoy en el albayzin', {}),
        // createChatBotMessage('Hola, están parando en la calle Fajalauza', {})
        //createChatBotMessage(t('hello_message'), {}),
        //createChatBotMessage(t('guide_message'), {widget: 'ncwGuide'}),
        //createChatBotMessage(t('info_message'), {widget: 'ncwHint'})
        createChatBotMessage('Hola! Soy el celestino empresarial inteligente de AJE Granada. Lo sé todo sobre las empresas y autónomos de AJE Granada, y lo mejor, sé quien puede hacer migas contigo: negocios, cubrir nuevas oportunidades, ... ¡preguntame y lo verás!', {})
    ] as any[];
    if (latestNews != null) {
        
        initMsgs.push(createCustomMessage(latestNews, 'nccAlert', {}));
    }
    return {
        botName: 'aje-matcher',
        initialMessages: initMsgs,
        customStyles: {
            botMessageBox: {
                backgroundColor: '#784F83'
            },
            chatButton: {
                backgroundColor: '#5ccc9d'
            }
        },
        customComponents: {
            header: (props) => <div></div>,
            botAvatar: (props) => <NcAvatar {...props} />,
            botChatMessage: (props) => <MarkdownChatMessage {...props} />,

        },
        customMessages: {
            'nccAlert': (props) => <NccAlert {...props} />
        },
        widgets: [
            {
                widgetName: 'ncwMap',
                widgetFunc: (props) => <NcwMap {...props} />
            },
            {
                widgetName: 'dogPicture',
                widgetFunc: (props) => <DogPicture {...props} />,
            },
            {
                widgetName: 'ncwHint',
                widgetFunc: (props) => <NcwHint {...props} />
            }

        ] as IWidget[]
    } as IConfig;
};

export default config;
